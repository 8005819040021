pre {
  overflow: auto;
  background: rgb(48, 48, 48);
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: monospace;
  padding: 1vh 0.5vw;
  white-space: pre-wrap;
  outline: 0;
  margin: 1vh 0.5vw;
  color: white;
}

.json-key { color: #a8dafb; }
.json-string { color: #aaddaa}
